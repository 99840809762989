import currenciesService from "../../../../services/currenciesService";
import { mapActions } from "vuex";
import * as types from "../../../../store/types";
// import userInfoService from "../../../../services/userInfoService";
// import userGateWayStatusService from "../../../../services/userGateWayStatusService";

const dashboardExchangeCard = () =>
    import("../../../../components/dashboardExchangeCard/index.vue");
const dashboardCurrencies = () =>
    import("../../../../components/dashboardCurrencies/index.vue");
export default {
    name: "payment",
    components: { dashboardExchangeCard, dashboardCurrencies },
    props: [],
    data() {
        return {};
    },
    computed: {},
    mounted() {
        // setTimeout(() => {
        //     this.getUserGatewayStatusData();
        // }, 2300);
    },
    created() {
        // this.getCurrenciesFunc();
        // this.getInfoData();
    },
    methods: {
        ...mapActions({
      userGateway: types.GET_USER_GATEWAY_STATUS,
    //   userInfoAction: types.ACTION_USER_INFO,
            currenciesAction: types.ACTION_CURRENCIES,
        }),
        getCurrenciesFunc() {
            currenciesService
                .getCurrencies()
                .then((response) => {
                    this.currenciesAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        // getUserGatewayStatusData() {
        //     userGateWayStatusService
        //         .getUserGateWay(this.userInfo.username, this.IPAddress)
        //         .then(response => {
        //             this.userGateWayAction(response.data);
        //         })
        //         .catch((error) => {
        //             this.$bvToast.toast(error.response.data.message, {
        //                 title: "خطا",
        //                 noCloseButton: true,
        //                 variant: "custom",
        //                 bodyClass:
        //                     "dangerBackgroundColor dangerFontColor rtl text-right",
        //                 headerClass:
        //                     "dangerBackgroundColor dangerFontColor rtl text-right",
        //                 solid: false,
        //             });
        //         })
        //         .finally(() => (this.loading = false));
        // },
        // getInfoData() {
        //     userInfoService
        //         .getUserInfo()
        //         .then((response) => {
        //             this.userInfoAction(response.data);
        //         })
        //         .catch((error) => {
        //             this.$bvToast.toast(error.response.data.message, {
        //                 title: "خطا",
        //                 noCloseButton: true,
        //                 variant: "custom",
        //                 bodyClass:
        //                     "dangerBackgroundColor dangerFontColor rtl text-right",
        //                 headerClass:
        //                     "dangerBackgroundColor dangerFontColor  rtl text-right",
        //                 solid: false,
        //             });
        //         })
        //         .finally(() => (this.loading = false));
        // },
    },
};
